<template>
  <div class="w-full max-w-sm mx-auto ">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AuthView",
};
</script>
 